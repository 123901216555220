import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css";
import Menu from "@/components/dropdown";
import axios from "@/axios";
import ProfileInfoCard from "@/components/profile-info-card";
import modalContent from "@/components/modal-content";
import Dropdown from "@/components/dropdown";
import ProfileInfo from "@/components/profile-info";
import tabs from "@shared/dashboard-components/tabs";
import Card from "@/components/sc-journey/card";
import Icons from "@/components/icons-list";
import Multiselect from "vue-multiselect";
import Input from "@/components/input";
import Button from "@/components/button/index.vue";
import DelIcon from "@/assets/icons/svg/delete-icon-white.svg";
import EncloseIcon from "@/assets/icons/profile-view/enclosure-icon-white.svg";
import CloseIcon from "@/assets/icons/svg/close-icon-white.svg";
import SendIcon from "@/assets/icons/profile-view/send.svg";
import SendLater from "@/assets/icons/profile-view/sendlater.svg";
import ToIcon from "@/assets/icons/profile-view/paperPlane.svg";
import ArrowIcon from "@/assets/icons/profile-view/arrow.svg";
import ArrowDown from "@/assets/icons/profile-view/arrow-blue.svg";
import DropDownArrow from "@/assets/icons/profile-view/arrow-white.svg";
import RefereeIcon from "@/assets/icons/profile-view/refree.svg";
import ClientIcon from "@/assets/icons/profile-view/client.svg";
import CandidateIcon from "@/assets/icons/profile-view/candidate.svg";
import Other from "@/assets/icons/profile-view/other-role-icon.svg";
import FromIcon from "@/assets/icons/profile-view/send-mail.svg";
import NotesIcon from "@/assets/icons/profile-view/wnotes.svg";
import MailIcon from "@/assets/icons/profile-view/wEmail.svg";
import BoldIcon from "@/assets/icons/profile-view/bold-icon.svg";
import ItalicIcon from "@/assets/icons/profile-view/italic-icon.svg";
import UnderlineIcon from "@/assets/icons/profile-view/underline-icon.svg";
import StrikeThroughIcon from "@/assets/icons/profile-view/strikethrough-icon.svg";
import CollapseIcon from "@/assets/icons/profile-view/collapse.svg";
import AddCheck from "@/components/add-check";
import { uuid } from "vue-uuid";
// const checklist = () => import("@shared/components/checklist");
const checklist = () => import("@/components/dashboard-components/checklist");
// import { checklist as checklistData } from "@/components/dashboard-components/checklist/utils/checklist-data.js";
import Timeline from "@shared/timeline";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapGetters, mapActions } from "vuex";
import FormBuilder from "@shared/components/form-builder";
import VueCookies from "vue-cookies";
const PdfViewModal = () => import("@shared/components/pdf-view/pdf-view-modal");
const NotesModal = () => import("@shared/components/modals/notes");
// import { DateTime } from "luxon";
import { FormatDate } from "@/plugins/functions";
const FilePreview = () => import("@shared/components/file-preview");
const WorkAllocation = () => import("@/pages/dashboard/components/work-allocation");

const MoreOptions = () => import("./components/moreoptions");
const fileManager = () => import("@shared/dashboard-components/fileManager");
import { EventBus } from "@/main.js";

export default {
    components: {
        quillEditor,
        AddCheck,
        FormBuilder,
        "menu-list": Menu,
        "profile-info": ProfileInfo,
        "profile-info-card": ProfileInfoCard,
        "icons-list": Icons,
        modalContent,
        "neo-input": Input,
        "neo-button": Button,
        "vue-multiselect": Multiselect,
        Card,
        UnderlineIcon,
        NotesIcon,
        MailIcon,
        tabs,
        CollapseIcon,
        StrikeThroughIcon,
        ArrowIcon,
        Dropdown,
        DropDownArrow,
        ItalicIcon,
        BoldIcon,
        FromIcon,
        EncloseIcon,
        ArrowDown,
        SendIcon,
        RefereeIcon,
        ClientIcon,
        CandidateIcon,
        Other,
        ToIcon,
        SendLater,
        DelIcon,
        CloseIcon,
        Timeline,
        PdfViewModal,
        NotesModal,
        FilePreview,
        WorkAllocation,
        fileManager,
        MoreOptions,
    },
    computed: {
        ...mapGetters(["getRefChecks", "getUser", "getCaseData", "getCaseNotificationCount", "getIsClientWorkFlowAdmin"]),
        moreOptionsComputed() {
            return this.moreOptions
                .filter(({ admin }) => !admin || (admin && this.getIsClientWorkFlowAdmin))
        },
        btns() {
            return [
                {
                    svg: "close-icon",
                    key: "in-progress",
                    name: "hello",
                    handleModal: this.select,
                },
                {
                    svg: "del-icon",
                    key: "on-hold",
                    name: "hello",
                    handleModal: this.select,
                },
            ];
        },
    },
    data() {
        return {
            isTimelineLoading: false,
            case_id: "",
            content: "",
            formPreviewData: null,
            delta: undefined,
            editorOptions: {
                debug: "info",
                placehoder: "this is a placeholder",
                readOnly: true,
                theme: "snow",
                modules: {
                    toolbar: [[{ size: ["small", false, "large", "huge"] }], ["bold", "italic", "underline", "strike"]],
                },
            },
            dummystatus: "none",
            uuid: uuid.v4(),
            sendOptions: false,
            value: null,
            emailAction: "",
            emailFormValues: {
                subject: "",
                html_body: "",
                references: "",
                in_reply_to: "",
                delivery_date: "2022-11-09T05:55:53.465Z",
                email_to: "",
                email_trail_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                external_id: "1122333444455555",
                tag: "metaTag",
            },
            roleOptions: false,
            isCollapsed: false,
            selected: "",
            sendToRole: "",
            activeCheck: "overview",
            tabs: {
                selected: 2,
                list: [
                    {
                        id: 1,
                        label: "Input",
                    },
                    {
                        id: 2,
                        label: "Check",
                    },
                    {
                        id: 3,
                        label: "Output",
                    },
                ],
            },
            roleValue: [],
            rolesOptions: ["one", "two", "three"],
            activeTab: "one",
            lists: [
                {
                    id: 3,
                    name: "Education",
                    svgName: "education",
                    enableCheck: true,
                },
                {
                    id: 4,
                    name: "Employment",
                    svgName: "employment",
                    key: "employment",
                    enableCheck: true,
                },
                {
                    id: 5,
                    name: "Gap Validation",
                    svgName: "difference",
                    key: "difference",
                    enableCheck: true,
                },
                {
                    id: 6,
                    name: "DBS Basic",
                    svgName: "other",
                    key: "dbsBasic",
                    enableCheck: true,
                },
                {
                    id: 7,
                    name: "Pro. Qualification and Membership",
                    svgName: "other",
                    key: "membership",
                    enableCheck: true,
                },
            ],
            notes: {
                type: "",
                note: "",
            },
            attachments: {
                mail_attachments: 0,
                notes_attachments: 0,
            },
            testList: [
                {
                    id: "1",
                    name: "MICROSOFT",
                    status: "completed",
                    notofocations_count: 2,
                    refree: {
                        name: "Alex Smith",
                        phone: "+9100000000000",
                        email: "test.test@gmail.com",
                    },
                    checked: true,
                },
                {
                    id: "2",
                    name: "FACEBOOK",
                    status: "action_needed",
                    notofocations_count: 1,
                    refree: {
                        name: "David",
                        phone: "+9100000000000",
                        email: "test.test@gmail.com",
                    },
                    checked: false,
                },
                {
                    id: "3",
                    name: "GOOGLE",
                    status: "on_hold",
                    notofocations_count: 1,
                    refree: {
                        name: "John",
                        phone: "+9100234234230",
                        email: "test@gmail.com",
                    },
                    checked: true,
                },
            ],
            statusList: [],
            candidateData: null,
            activeIcon: "overview",
            cadidateFormStatusTimeline: {
                header: {
                    label: "Subject Form Status",
                    icon: "#",
                },
                timeline: [],
            },
            checksTimeline: [
                {
                    header: {
                        label: "Gap Validation",
                        icon: "#",
                    },
                    timeline: [],
                },
                {
                    header: {
                        label: "Address History",
                        icon: "#",
                    },
                    timeline: [],
                },
            ],
            selectedConfig: {
                history: {
                    action: () => {
                        this.activeCheck = "";
                    },
                    component: checklist,
                    props: {
                        list: [],
                        isLoading: false,
                    },
                },
                "file-manager": {
                    init: async function (id, type = "input") {
                        this.methods.changeSettings(id, type);
                        this.props.directory_type = type;
                        this.props.directories = [];
                        this.props.isLoading = true;
                        this.props.directories = await this.methods.fetchFiles(id, type);
                        this.props.isLoading = false;
                        console.log(this.props.directories)
                    },
                    methods: {
                        changeSettings: (id) => {
                            if (!id) this.activeCheck = "";
                            this.activeIcon = "file-manager";
                            this.activeType = null;
                            this.showSubTypeCard = false;
                            this.showCandidateForm = false;
                        },
                        fetchFiles: async (id, type) => {
                            // const id = payload || this.$route?.query["caseid"];
                            const payload = {
                                case_id: this.$route?.query?.caseid,
                                // check_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                                directory_type: type,
                            };
                            if (id) {
                                payload.check_id = id;
                            }
                            try {
                                const { data } = await axios.post(`/case-file-manager/client/get-file-data`, payload);
                                return data?.data ?? [];
                            } catch (error) {
                                console.log(error, "[+] error while fetching files");
                            }
                        },
                    },
                    component: fileManager,
                    props: {
                        tabs: [
                            { key: 1, name: "Input", queryKey: "input" },
                            // { key: 2, name: "Internal", queryKey: "internal" },
                        ],
                        selected: 1,
                        profileTab: () => {
                            return 1;
                        },
                        report: {
                            loading: () => this.loadingState.report,
                            generateReport: this.generateReport,
                        },
                        showTabs: true,
                        directories: [],
                        isLoading: false,
                        directory_type: "input",
                    },
                },
                // overview: {
                //   action: () => {
                //     this.activeCheck = "";
                //   },
                //   component: overview,
                //   props: {

                //   }
                // }
            },
            pdfLink: null,
            loadingState: {
                report: false,
                teams: false,
                users: false,
                getAllocation: false,
            },
            allTeams: [],
            allUsers: [],
            teamsStates: [],
            usersStates: [],
            allocatedResources: [],
            client_id: null,
            moreOptions: [
                {
                    id: 1,
                    label: "Case Allocation",
                    value: "case-allocation",
                    admin: true
                }
            ],
            gapErrors: {},
            gapErrorMsg: '',
        };
    },
    watch: {
        content(val) {
            this.delta = val;
        },
    },
    methods: {
        ...mapActions(["fetchCaseNotificationCount", "fetchNotificationCount"]),
        getCaseDetails(data) {
            this.candidateData = data;
        },
        select(status, svg) {
            this.dummystatus = status;
            this.svg = svg;
        },
        async selectTab(id) {
            this.$set(this.selectedConfig["file-manager"].props, "showTabs", id !== 3);
            this.tabs.selected = id;
            await this.fetchFormPreview();
            if (this.activeIcon && id !== 1) {
                let type = null;
                if (this.activeIcon === "file-manager") {
                    this.$refs.activeComp.resetState();
                    if (id === 3) {
                        type = "output";
                    } else {
                        type = "input";
                    }
                }
                await this.selectedConfig[this.activeIcon].init(null, type);
            }
            // if (!this.formPreviewData)

        },
        selectCard(id) {
            this.selected = this.selected === id ? "" : id;
        },
        collapse() {
            this.isCollapsed = !this.isCollapsed;
        },
        handleRoles(role) {
            this.sendToRole = role;
            this.roleOptions = false;
        },
        isClicked(value) {
            // this.$router.push({ path: '/profile-view?', params: { location: 'Milwaukee', user: 3, type: 'support' } })
            if (this.activeCheck === value.key && value.key !== 'add-check') return;
            if (value.key === 'add-check') {
                this.$refs["add-check-modal"].showCheckModal();
                return;
            }
            this.activeCheck = value.key;
            this.activeIcon = "";
        },
        submitPreviewForm(payload) {
            console.log(payload, ">>>>>> save form payload ");
        },

        hanldeCloseModal(modal_name) {
            this.$modal.hide(modal_name);
        },
        async handleSelectIconList(modal_name) {
            this.activeCheck = "";
            this.activeIcon = modal_name;
            switch (modal_name) {
                case "history":
                    await this.fetchCaseNotes();
                    break;
                case "file-manager":
                    await this.selectedConfig[modal_name].init(null, this.tabs.selected === 3 ? "output" : "input");
                    this.$refs.activeComp.resetState();
                    break;
                default:
                    break;
            }
        },
        // handleShowModal(modal_name) {
        //   // this.selectedField = null;
        //   this.$modal.show(modal_name);
        //   this.activeIcon = modal_name;
        //   if (
        //     this.selectedConfig[modal_name] &&
        //     this.selectedConfig[modal_name].action
        //   ) {
        //     this.selectedConfig[modal_name].action();
        //   }
        // },
        async method(modal_name) {
            if (modal_name == "add-note") {
                await this.createCaseNotes(modal_name);
            }
            if (modal_name == "send-mail") {
                this.sendOptions = !this.sendOptions;
            }
            if (modal_name == "show-roles") {
                this.roleOptions = !this.roleOptions;
            }
        },
        closeMethod() {
            this.roleOptions = false;
            this.sendOptions = false;
        },
        async sendEmail() {
            await axios.post("email/send", {
                subject: this.emailFormValues.subject,
                html_body: this.delta,
                email_to: this.emailFormValues.email_to,
                external_id: this.uuid,
                tag: this.emailFormValues.tag,
            });
            this.fetchNotificationCount();
            this.fetchCaseNotificationCount(this.case_id);
            this.hanldeCloseModal("mail");
            // }
        },
        formatSections(data) {
            return { sections: [data.data] };
        },
        async fetchFormPreview() {
            // Remove hardcoded screening package id(for debugging purposes only)
            const case_id = this.$route.query.caseid;
            const url = `/case/form/${case_id}/client`;
            try {
                const data = await axios.get(url);
                this.formPreviewData = data.data;
            } catch (error) {
                console.error("[+] error in fetching form data", error);
            }
        },

        async saveSection(payload) {
            // reset gap errors before new check:
            this.gapErrors = {
                gapErrorsView: false,
            };
            this.gapErrorMsg = '';

            const case_id = this.$route.query.caseid;
            const url = `/case/form/${case_id}/client`;
            try {
                const response = await axios.post(url, this.formatSections(payload));
                // this.$toast.success("Case submitted successfully!");
                return response;
            } catch (error) {
                const { error: resp } = error?.response?.data?.detail;
                if (resp) {
                    if (resp.code === "ERROR.GAP_VALIDATION") {
                        this.gapErrors = {
                            message: resp.message,
                            gaps: resp.gaps,
                            gapErrorsView: true,
                        };
                        this.gapErrorMsg = resp.code;
                        this.scrollToInvalidField();
                    }
                }
                this.$toast.error(error?.response?.data?.detail?.error?.message || "Failed to save");
                throw error;
            }
        },
        scrollToInvalidField(){
            setTimeout(()=> {
                this.$nextTick(()=>{
                    document.querySelectorAll('.form-field--invalid')[0]?.scrollIntoView({ behavior: "smooth"})
                })
            },0)
        },
        // async submitCaseForm(caseId) {
        //     const url = `/case/form/${caseId}/client/submit`;
        //     try {
        //         const response = await axios.post(url);
        //     } catch (error) {
        //         return false;
        //     }
        // },
        async saveForm(payload) {
            try {
                const response = await this.saveSection(payload);
                // if (sectionSaved) {
                //     await this.submitCaseForm(this.case_id);
                //     this.showFormSubmissionNotification = true;
                //     this.gapErrors = {
                //         gapErrorsView: false,
                //     };
                // }
                this.$toast.success(response?.data?.message ? response?.data?.message : "Form submitted!")
            } catch (error) {
                this.formSubmissionStatusMessage = "Could not process form at the moment.please contact administrator.";
                // this.showFormSubmissionNotification=true
            }
            // this.showFormSubmissionNotification=true
        },

        handleClearCallActiveLog() {
            this.activeCallLog = null;
        },
        async generateReport() {
            this.loadingState.report = true;
            // this.$modal.show("pdf-view-modal");
            try {
                let url = `report/generate/case?case_id=${this.case_id}`;
                let { data } = await axios.get(url);
                this.pdfLink = data.report_url;
                window.open(this.pdfLink);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Error While creating report");
            }
            this.loadingState.report = false;
        },
        onNotesChange({ target }) {
            this.notes.note = target.value;
        },
        handleResetCaseNotes() {
            this.notes = {
                type: "",
                note: "",
                attachments: null,
            };
        },
        fetchFileName(event, id) {
            // code for attached file names
            const { files } = event.target;
            if (id == "notes_attachments") {
                this.notes.attachments = files;
                this.attachments.notes_attachments = files.length;
            }
        },
        async createCaseNotes() {
            let notes = this.notes;
            let payload = {
                user_id: this.getUser.id,
                note_text: notes.note,
                note_type: "customer",
                attachments: notes.attachments,
                external_id: notes.external_id || this.case_id,
                external_id_type: "candidate",
                case_id: this.case_id,
            };
            if (!this.notes.note) return this.$toast.error("Please Fill mandatory fields");
            let formData = new FormData();
            formData.append("user_id", payload.user_id);
            formData.append("note_text", payload.note_text);
            formData.append("note_type", payload.note_type);
            if (payload.attachments && payload.attachments.length > 0) {
                for (let i = 0; i < payload.attachments.length; i++) {
                    const element = payload.attachments[i];
                    formData.append("attachments", element);
                }
            }
            formData.append("external_id", payload.external_id);
            formData.append("external_id_type", payload.external_id_type);
            formData.append("case_id", payload.case_id);
            try {
                await axios.post("/case-notes/client", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                this.$toast.success("notes created");
                this.hanldeCloseModal("notes");
                this.handleResetCaseNotes();
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.case_id);
                await this.fetchCaseNotes();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to create notes");
            }
        },
        async fetchCaseNotes() {
            try {
                this.$set(this.selectedConfig[this.activeIcon].props, "list", []);
                this.$set(this.selectedConfig[this.activeIcon].props, "isLoading", true);
                let form_data = {
                    external_id_type: "candidate",
                    external_id: this.case_id,
                    activity_type: "all",
                };
                let { data } = await axios.post("/activity-history/client", form_data);
                this.selectedConfig.history.props.list = data.map((elem) => {
                    return {
                        ...elem,
                        date: FormatDate(new Date(elem.event_timekey)),
                        id: elem.id,
                        type: elem.activity_type,
                        data: {
                            description: elem.note_text,
                            // reply", "reply-all", "forward"
                            actions: elem.activity_type === "email" ? ["reply", "reply-all", "forward", "check"] : elem?.attachments?.length > 0 ? ["badge", "attachments", "check"] : ["badge", "check"],
                        },
                    };
                });
            } catch (error) {
                console.log(error, "[+] Error in fetching activity");
            }
            this.$set(this.selectedConfig[this.activeIcon].props, "isLoading", false);
        },
        async fetchTimeline() {
            this.isTimelineLoading = true;
            const case_id = this.case_id;
            let status_timeline = {
                header: {
                    label: "Subject Form Status",
                    icon: "#",
                },
                timeline: [],
            };
            let non_timeline = {
                header: {
                    label: "Checks",
                    icon: "#",
                },
                timeline: [],
                showDates: false,
            };

            // todo instead of calling api's sequentally
            // make a Promise array and fetch them all at once
            try {
                const { data } = await axios(`case/${case_id}/events-timeline/client`);
                if (data)
                    status_timeline.timeline = data.data.map((el) => ({
                        date: new Date(el.timestamp),
                        title: el.event_name,
                        description: "",
                        color: el.status_color || "#3b2cd4",
                    }));
            } catch (error) {
                console.log("error :>> ", error);
                this.$toast.error(error.response.data.detail);
            }
            try {
                const { data } = await axios(`case/${case_id}/checks-timeline/client`);
                this.checksTimeline = data.data.map((obj) => {
                    let data = {};
                    data = {
                        header: {
                            label: obj.check_name,
                            icon: "#",
                        },
                    };
                    data.timeline = obj.timeline.map((el) => ({
                        date: new Date(el.from_date),
                        title: el.title,
                        description: el.status_name,
                        color: el.status_color,
                    }));
                    return data;
                });
            } catch (error) {
                console.log("error :>> ", error);
            }
            try {
                const { data } = await axios(`case/${case_id}/non-timeline-checks/client`);
                if (data) {
                    non_timeline.timeline = data.data.map((el) => ({
                        // adding a dummy for key and contents
                        date: new Date(),
                        title: el.check_name || "-",
                        description: el.status_name,
                        color: el.status_color || "#3b2cd4",
                    }));
                }
            } catch (error) {
                console.log("error3 :>> ", error);
            }
            this.isTimelineLoading = false;
            this.checksTimeline.push(...[status_timeline, non_timeline]);
        },

        async removeBlock(payload) {
            const url = `/case/form/${this.case_id}/multi-block/${payload}`;
            let data = await axios.delete(url);
            if(data?.data.success) { 
                this.blockRemoved= true;
                EventBus.$emit("removedBlock",this.blockRemoved);
            }
        },
        async removeField(payload) {
            const url = `/case/form/${this.case_id}/multi-field/${payload}`;
            await axios.delete(url);
        },

        // Case Allocation

        async onWorkAllocation() {
            this.usersStates = [];
            this.teamsStates = [];
            // this.fetchAllTeams();
            // this.fetchAllUsers();
            this.fetchAllocatedStates("users", [this.case_id]);
            this.fetchAllocatedStates("teams", [this.case_id]);
            this.$modal.show("work-allocation");
        },
        async fetchAllocatedStates(type, caseId) {
            let url = "";
            const payload = {
                case_ids: caseId.map((el) => ({ id: el })),
            };
            this.loadingState[type] = true;
            if (type === "users") {
                url = "/client-case-allocation/case/user-state";
            } else {
                this.loadingState.teams = true;
                url = "/client-case-allocation/case/group-state";
            }
            try {
                const { data } = await axios.post(url, payload);
                this[`${type}States`] = data?.map((res) => ({ ...res, status: null, id: type === "users" ? res.id : res.group_id }));
            } catch (error) {
                console.log(error, "[+] error while fetching teams");
            }
            this.loadingState[type] = false;
        },
        onWorkAllocateChange({ index, value, type }) {
            this[type][index].status = this[type][index].status === value ? null : value;
        },
        async onWorkAllocationSave({ type }) {
            //type: team or user
            let url = "";
            let payload = {
                case_ids: [this.case_id].map((cas) => ({ id: cas })),
            };
            if (type === "team") {
                url = "/client-case-allocation/case/group";
                payload.add_groups = this.teamsStates.filter((user) => user.status === "add").map((elem) => elem.id);
                payload.remove_groups = this.teamsStates.filter((user) => user.status === "remove").map((elem) => elem.id);
            } else {
                url = "/client-case-allocation/case/user";
                payload.add_users = this.usersStates.filter((user) => user.status === "add").map((elem) => elem.id);
                payload.remove_users = this.usersStates.filter((user) => user.status === "remove").map((elem) => elem.id);
            }
            try {
                await axios.post(url, payload);
                this.$toast.success("Saved Successfully!");
            } catch (error) {
                this.$toast.error("Saving Failed!");
            }
        },
        async onAllocationReset({ type }) {
            await this.fetchAllocatedStates(type, [this.case_id]);
        },
        async onOptionClicked(item) {
            switch (item.value) {
                case "case-allocation":
                    await this.onWorkAllocation();
                    break;

                default:
                    break;
            }
        },
        onFileManagerTabChange(tab) {
            this.$refs.activeComp.resetState();
            this.$set(this.selectedConfig["file-manager"].props, "selected", tab.key);
            this.selectedConfig["file-manager"].init(null, tab.queryKey);
        },
        async onAddNewFolder(details = {}) {
            try {
                const payload = {
                    case_id: this.case_id,
                    directory_type: details.directory_type,
                    resource_type: "directory",
                    name: details.name,
                    description: details.description,
                    path: `${details.content.path}/${details.name}`,
                };
                this.$set(this.selectedConfig["file-manager"].props, "isLoading", true);
                await this.uploadDirectoryContent(payload);
                this.$toast.success("Folder added successfully")
                await this.selectedConfig["file-manager"].init(null, details.directory_type);
            } catch (error) {
                console.log(error, "[+] error while adding directory");
            }
        },
        closeActiveComponent(){
            this.activeCallLog = null;
            this.activeIcon = "";
            this.isCollapsed = false;
            this.showCandidateForm = false;
        },
        async onAddFiles({ files, content, directory_type }) {
            try {
                const formData = new FormData();
                formData.append("file", files[0].file);
                this.$set(this.selectedConfig["file-manager"].props, "isLoading", true);
                const { data } = await axios.post(`/case-file-manager/${this.case_id}/upload-file`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                const payload = {
                    case_id: this.case_id,
                    directory_type: directory_type,
                    resource_type: "file",
                    name: data?.data?.file_path ?? "",
                    path: `${content.path}/${data?.data?.file_path ?? ""}`,
                };
                this.$toast.success("File Added successfully")
                await this.uploadDirectoryContent(payload);
            } catch (error) {
                console.log(error, "[+] error while adding files");
            }
            await this.selectedConfig["file-manager"].init(null, directory_type);
        },
        async uploadDirectoryContent(payload = {}) {
            try {
                await axios.post("/case-file-manager", payload);
            } catch (error) {
                this.$toast.error(error?.response.data.detail ? error?.response.data.detail: "Some error occured while adding a folder");
            }
        },
        async onDeleteDirectory(data) {
            try {
                this.$set(this.selectedConfig["file-manager"].props, "isLoading", true);
                await axios.delete(`/case-file-manager/${data.id}`);
            } catch (error) {
                console.log(error, "[+] error while deleting the directory");
            }
            await this.selectedConfig["file-manager"].init(null, data.directory_type);
        },
        async onFileOpen(details) {
            this.$set(this.selectedConfig["file-manager"].props, "isLoading", true);
            try {
                const { data } = await axios.get(`/case-file-manager/get-file-url/${details.file_id}`);
                window.open(data.url, "_blank");
            } catch (error) {
                console.log(error, "[+] failed to fetch file");
            }
            this.$set(this.selectedConfig["file-manager"].props, "isLoading", false);
        },

        async refetchRefChecks() {
            await this.fetchTimeline();
        },
    },

    beforeMount(){
        if (this.$route.hash.includes('#fileManager')) {
            VueCookies.set("next-url", this.$route.fullPath)
            console.log(this.$route.query.clientid)
            // if (this.$route.query.clientid !== this.$store.getters.getClientId)
            //     this.$store.dispatch("logoutUser");
            // else {
                this.tabs.selected = 3
            // }
        }
        else {
            if (VueCookies.get("next-url").includes("#fileManager") && VueCookies.get("next-url")?.split('clientid=')[1]?.split('#fileManager')[0] !== this.$store.getters.getClientId)
            this.$store.dispatch("logoutUser");
            else if (VueCookies.get("next-url").includes('#fileMagager')) {
            this.tabs.selected = 3
        }
    }
    },

    async mounted() {
        this.case_id = this.$route.query.caseid;
        if (!this.$store.getters.getClientId) {
            await this.$store.dispatch("fetchClientId");
        }
        this.client_id = this.$store.getters.getClientId;
        // await this.fetchCaseNotes();
        this.isTimelineLoading = true;
        await this.fetchTimeline();
        this.isTimelineLoading = false;
        if (this.$route.query.action) {
            let active_action = this.$route.query.action;
            this.handleSelectIconList(active_action);
        }
        await this.fetchCaseNotificationCount(this.case_id);
        if (this.$route.hash.includes('#fileManager')) {
            VueCookies.set("next-url", this.$route.fullPath)
            console.log(this.$route.query.clientid)
            if (this.$route.query.clientid !== this.$store.getters.getClientId)
                this.$store.dispatch("logoutUser");
            else {
                this.tabs.selected = 3
                this.activeIcon = "file-manager"
                await this.selectedConfig['file-manager'].init(null, this.tabs.selected === 3 ? "output" : "input");
            }
        }
       
        if (VueCookies.get("next-url").includes("#fileManager") && VueCookies.get("next-url")?.split('clientid=')[1]?.split('#fileManager')[0] !== this.$store.getters.getClientId)
            this.$store.dispatch("logoutUser");
        else if (VueCookies.get("next-url").includes('#fileMagager')) {
            this.tabs.selected = 3
            this.activeIcon = "file-manager"
            await this.selectedConfig['file-manager'].init(null, this.tabs.selected === 3 ? "output" : "input");
        }

    },
};
